import moment from "moment";
import { Loader } from "rsuite";

const InfoCard = ({ patient, isAdmin, handleSync, isLoadingSync }) => {
  const {
    firstName,
    lastName,
    birthDate,
    linePhone,
    mobilePhone,
    email,
    socialSecurityNumber,
    location,
    syncInProgress,
    lastSync,
    prescribers,
    salesManager,
    nursingConsultant,
    regionalManager,
    //directors,
  } = patient || {};

  const InfoRow = ({ label, value }) =>
    value ? (
      <div>
        <span className="font-semibold">{label} :</span> <span>{value}</span>
      </div>
    ) : null;

  const calculateAge = (birthDate) => moment().diff(moment(birthDate), "years");

  return (
    <div>
      <div className="mb-3 text-2xl font-semibold text-primary">
        {lastName} {firstName}
      </div>
      <div className="flex flex-col gap-1">
        {birthDate && (
          <>
            <div>{calculateAge(birthDate)} ans</div>
            <div>
              Né(e) le <span>{moment(birthDate).format("DD/MM/YYYY")}</span>
            </div>
          </>
        )}
        <InfoRow label="Tel fixe" value={linePhone} />
        <InfoRow label="Tel mobile" value={mobilePhone} />
        {email &&
          !email.includes("@isisdiabete.fr") &&
          !email.includes("ISISDIABETE.FR") && (
            <InfoRow label="Email" value={email} />
          )}
        <InfoRow label="N° sécurité social" value={socialSecurityNumber} />
        {location && (
          <span>
            <span className="font-semibold">Adresse :</span>{" "}
            {location.streetNumber} {location.street}, {location.zipCode}{" "}
            {location.city}
          </span>
        )}
      </div>
      {isAdmin && (
        <div className="flex flex-col gap-1">
          <button
            className={`px-3 py-1 mt-5 font-semibold border flex justify-center items-center gap-2 rounded w-fit border-dark text-dark hover:bg-dark hover:text-white ${
              isLoadingSync || syncInProgress ? "opacity-70 cursor-default" : ""
            }`}
            onClick={async () => await handleSync(patient._id)}
            disabled={isLoadingSync || syncInProgress}
          >
            Synchroniser le patient{" "}
            {isLoadingSync || syncInProgress ? <Loader /> : null}
          </button>
          <span className="text-xs">
            {syncInProgress
              ? "Synchronisation en cours..."
              : lastSync
              ? `Dernière maj manuelle : ${moment(lastSync).format(
                  "DD/MM/YYYY HH:mm"
                )}`
              : ""}
          </span>
        </div>
      )}
      <hr className="my-5" />
      <div className="flex flex-col gap-1">
        {prescribers && prescribers.length > 0 && (
          <InfoRow
            label="Prescripteurs"
            value={prescribers.map((prescriber) => prescriber.name).join(" - ")}
          />
        )}
        <InfoRow label="Responsable commercial" value={salesManager} />
        {nursingConsultant && nursingConsultant.length > 0 && (
          <InfoRow
            label="Infirmiers"
            value={nursingConsultant.map((nurse) => nurse.name).join(" - ")}
          />
        )}
        {regionalManager && regionalManager.length > 0 && (
          <InfoRow
            label="Responsable de région"
            value={regionalManager.map((manager) => manager.name).join(" - ")}
          />
        )}
        {/*directors && directors.length > 0 && (
          <InfoRow
            label="Directeurs"
            value={directors.map((director) => director.name).join(" - ")}
          />
        )*/}
      </div>
    </div>
  );
};

export default InfoCard;
