import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, CustomProvider } from "rsuite";

import frFR from "rsuite/locales/fr_FR";

import { AuthProvider } from "context/auth-context";
import { ModalProvider } from "context/modal-context";

import App from "./App";
import Modal from "./components/Modal";

import "rsuite/dist/rsuite.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./themes/fonts.css";
import "./themes/index.css";
import "./themes/rsuite.custom.css";

const container = document.getElementById("root");

if (container) {
  const root = ReactDOM.createRoot(container);

  root.render(
    <AuthProvider>
      <CustomProvider locale={frFR}>
        <Container>
          <ModalProvider>
            <App />
            <Modal />
            <ToastContainer />
          </ModalProvider>
        </Container>
      </CustomProvider>
    </AuthProvider>
  );
}
